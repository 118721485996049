import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/cms-template.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeatureTable = makeShortcode("FeatureTable");
const FeatureTableColumn = makeShortcode("FeatureTableColumn");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Safety`}</h1>
    <p>{`FoxTrot prides its self on embracing a positive safety culture. Each Flight Department, Facility, or Client embraces safety differently. FoxTrot will work with the Client and comply with all safety regulations of the Client. Furthermore, FoxTrot will work directly with the Client’s safety team to ensure best practices are being followed.`}</p>
    <FeatureTable mdxType="FeatureTable">
  <FeatureTableColumn header='Accountability' mdxType="FeatureTableColumn">
Online database of current SDS sheets in full compliance with the globally harmonized standard (GHS).
        <p>{`Tool control system and FOD risk mitigation system.`}</p>
  </FeatureTableColumn>
  <FeatureTableColumn header='Training' mdxType="FeatureTableColumn">
Professional uniforms conforming to customer needs.
        <p>{`Mandatory training`}</p>
        <ol>
          <li parentName="ol">{`aircraft detailing`}</li>
          <li parentName="ol">{`ladder safety`}</li>
          <li parentName="ol">{`aerial work platform safety`}</li>
          <li parentName="ol">{`scaffold safety`}</li>
          <li parentName="ol">{`fall protection`}</li>
        </ol>
        <p>{`FAA regulated drug and alcohol program.`}</p>
  </FeatureTableColumn>
  <FeatureTableColumn header='Supplier' mdxType="FeatureTableColumn">
Supplier must have a documented tool control program.
        <p>{`Supplier must have a documented waste control and disposal program.`}</p>
  </FeatureTableColumn>
    </FeatureTable>
    <h2>{`The FoxTrot difference`}</h2>
    <ol>
      <li parentName="ol">{`Investment in Team Member Training`}
        <ul parentName="li">
          <li parentName="ul">{`On boarding/recurring training`}</li>
          <li parentName="ul">{`NATA Line Service`}</li>
          <li parentName="ul">{`Ariel Work Platform (AWP) certification`}</li>
          <li parentName="ul">{`Fall safe certification`}</li>
          <li parentName="ul">{`Ladder training`}</li>
          <li parentName="ul">{`Scaffolding certification`}</li>
          <li parentName="ul">{`Etc..`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Identify and Control Hazards`}
        <ul parentName="li">
          <li parentName="ul">{`Watch employees work to spot unsafe work practices`}</li>
          <li parentName="ul">{`Perform Job Hazard Analysis`}</li>
          <li parentName="ul">{`Watch employees work to spot unsafe work practices`}</li>
          <li parentName="ul">{`Perform Job Hazard Analysis`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Positive Safety Culture`}
        <ul parentName="li">
          <li parentName="ul">{`Non punitive environment`}</li>
          <li parentName="ul">{`Safety conscious job approach`}</li>
          <li parentName="ul">{`Tool box talks (safety briefings x2 on a job site)`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Peer safety enforcement`}
        <ul parentName="li">
          <li parentName="ul">{`Consistent reporting of hazards`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      